import React from "react";
import { Grid, Typography } from "@mui/material";
import { useAuth0 } from "../../components/FischerFramework/FischerOAuth";

export default () => {
  const { isAuthenticated } = useAuth0();

  return (
    <Grid item>
      <Typography component="h2" variant="h2" gutterBottom>
        Welcome
      </Typography>
      <Typography component="p" variant="body1">
        {isAuthenticated
          ? "You need additional permissions"
          : "Please log in to create a closing survey"}
      </Typography>
    </Grid>
  );
};
