import React, { createContext, useContext, useState } from "react";
import * as Sentry from "@sentry/browser";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

interface FischerSnackbarContext {
  captureException: (exception: Error, message?: JSX.Element | string) => void;
  setSnackbar: (
    value:
      | ((
          prevState: JSX.Element | string | undefined
        ) => JSX.Element | string | undefined)
      | JSX.Element
      | string
      | undefined
  ) => void;
  snackbar: JSX.Element | string | undefined;
}

const defaultValue = {
  captureException: () => {},
  setSnackbar: () => {},
  snackbar: undefined,
};

const SnackbarContext = createContext<FischerSnackbarContext>(defaultValue);

export const useSnackbar = () => useContext(SnackbarContext);

export default ({ children }: React.ComponentProps<any>) => {
  const [value, setValue] = useState<FischerSnackbarContext>(
    Object.assign({}, defaultValue, {
      captureException: (exception: Error, message?: JSX.Element | string) => {
        if (!value.snackbar) {
          Sentry.captureException(exception);

          setValue(
            Object.assign({}, value, {
              snackbar: message || exception.message,
            })
          );
        }
      },
    })
  );

  function onClose() {
    setValue(
      Object.assign({}, value, {
        snackbar: undefined,
      })
    );
  }

  return (
    <SnackbarContext.Provider value={value}>
      {children}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        action={
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
        message={value.snackbar}
        onClose={onClose}
        open={!!value.snackbar}
      />
    </SnackbarContext.Provider>
  );
};
