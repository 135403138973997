import React, { ReactElement, useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Button from "@mui/material/Button";
import Nav, { drawerWidth } from "./Nav";
import { useAuth0 } from "../FischerFramework/FischerOAuth";
import Account from "./Account";
import { NavLinkProps } from "../NavLink/NavLink";
import VersionComponent from "../VersionComponent";

const PREFIX = "HeaderAndNav";

const classes = {
  appBar: `${PREFIX}-appBar`,
  appBarShift: `${PREFIX}-appBarShift`,
  menuButtonHidden: `${PREFIX}-menuButtonHidden`,
  title: `${PREFIX}-title`,
  titleShift: `${PREFIX}-titleShift`,
};

const Root = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [`&.${classes.appBarShift}`]: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.menuButtonHidden}`]: {
    display: "none",
  },
  [`& .${classes.title}`]: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

interface HeaderAndNavProps {
  children?: ReactElement<NavLinkProps> | ReactElement<NavLinkProps>[];
  title: string;
}

/**
 * A complex nav component.
 * Will render the header app bar and the side nav.
 * Side nav will render if the children are viewable.
 */
export default ({ children, title }: HeaderAndNavProps) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [open, setOpen] = useState<boolean>(mdUp);
  const { auth0Client, canView, isAuthenticated, user } = useAuth0();
  const hasNav = hasViewableNavLink(children);

  function hasViewableNavLink(
    navList?: ReactElement<NavLinkProps> | ReactElement<NavLinkProps>[]
  ) {
    // If the navList is an array, check each component for can(has)
    if (Array.isArray(navList)) {
      const viewableNavLink = navList.filter((navLink) => {
        return canView(navLink.props.has, navLink.props.hasAny);
      });

      if (viewableNavLink.length) {
        return true;
      }

      // Else if a single component, check can has on just that one
    } else if (navList) {
      return canView(navList.props.has, navList.props.hasAny);
    }

    return false;
  }

  useEffect(() => {
    setOpen(mdUp);
  }, [mdUp]);

  async function logIn() {
    await auth0Client!.loginWithRedirect();
  }

  async function logOut() {
    await auth0Client!.logout({
      returnTo: window.location.origin,
    });
  }

  function handleDrawerToggle() {
    setOpen(!open);
  }

  function onClickNav() {
    if (!mdUp) {
      setOpen(false);
    }
  }

  return (
    <>
      <Root
        className={`${classes.appBar} ${open && hasNav && classes.appBarShift}`}
        enableColorOnDark
      >
        <Toolbar>
          {hasNav ? (
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerToggle}
              className={`${open && classes.menuButtonHidden}`}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          ) : null}
          {!open || smUp ? (
            <Typography
              component="h1"
              className={`${classes.title} ${
                open && hasNav && classes.titleShift
              }`}
              noWrap
              variant="h6"
            >
              {title}
            </Typography>
          ) : null}
          {isAuthenticated ? (
            <Account logOut={logOut} user={user} />
          ) : (
            <Button color="inherit" onClick={logIn}>
              Log in
            </Button>
          )}
          <VersionComponent />
        </Toolbar>
      </Root>

      {hasNav ? (
        <Nav
          handleDrawerToggle={handleDrawerToggle}
          onClick={onClickNav}
          open={open}
        >
          {children}
        </Nav>
      ) : null}
    </>
  );
};
