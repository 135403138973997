import React, { useState } from "react";
import PropTypes from "prop-types";

// Material UI
import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";

// Custom
import PopoverComponent from "./PopoverComponent";

// CSS

const PREFIX = "IconBtnPopoverComponent";

const classes = {
  list: `${PREFIX}-list`,
};

const Root = styled(IconButton)(({ theme }) => ({
  [`& .${classes.list}`]: {
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
  },
}));

const IconBtnPopoverComponent = (props) => {
  const {
    badge,
    id,
    icon,
    anchorOrigin,
    transformOrigin,
    children,
    showPopover,
    setShowPopover,
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const badgeCount = badge >= 1 ? badge : undefined;

  const handleClick = (event) => {
    if (setShowPopover) {
      setShowPopover(true);
    }
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    if (setShowPopover) {
      setShowPopover(false);
    }
    setAnchorEl(null);
  };
  const open = showPopover !== undefined ? showPopover : Boolean(anchorEl);

  return (
    <>
      <Root
        aria-describedby={id}
        backgroundColor="primary"
        onClick={handleClick}
        size="large"
      >
        <Badge badgeContent={badgeCount} color="secondary">
          {icon}
        </Badge>
      </Root>
      <PopoverComponent
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {children}
      </PopoverComponent>
    </>
  );
};
// define these for destructuring props
IconBtnPopoverComponent.propTypes = {
  badge: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  id: PropTypes.string,
  showPopover: PropTypes.bool,
  setShowPopover: PropTypes.func,
  icon: PropTypes.objectOf(PropTypes.any),
  anchorOrigin: PropTypes.objectOf(PropTypes.any),
  transformOrigin: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
};
// gotta have this if the above prop types are not required
IconBtnPopoverComponent.defaultProps = {
  badge: undefined,
  id: undefined,
  icon: undefined,
  anchorOrigin: undefined,
  transformOrigin: undefined,
  children: undefined,
  showPopover: undefined,
  setShowPopover: undefined,
};

export default IconBtnPopoverComponent;
