import React from "react";
import FischerTheme from "./FischerTheme";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import * as Sentry from "@sentry/browser";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";
import { styled } from "@mui/material/styles";

const Root = styled(Container)(({ theme }) => ({
  marginTop: theme.spacing ? theme.spacing(2) : 16,
}));

export default ({ eventId }: { eventId: string | null }) => {
  return (
    <FischerTheme>
      <Root>
        <Typography variant="h1" gutterBottom>
          Something went wrong.
        </Typography>

        {eventId ? (
          <Button onClick={() => Sentry.showReportDialog({ eventId })}>
            Report feedback
          </Button>
        ) : (
          <Typography paragraph>
            Sorry about that. Please contact{" "}
            <Link color="secondary" href="mailto:helpdesktfg@fischerhomes.com">
              helpdesktfg@fischerhomes.com
            </Link>{" "}
            for help.
          </Typography>
        )}
      </Root>
    </FischerTheme>
  );
};
