import React from "react";
import { Grid, Typography } from "@mui/material";

export default () => (
  <Grid item>
    <Typography component="h2" variant="h2" gutterBottom>
      Thank you
    </Typography>
    <Typography component="p" variant="body1">
      Thank you for submitting the closing survey.
    </Typography>
  </Grid>
);
