import React from "react";
import PropTypes from "prop-types";
// Material UI
import Popover from "@mui/material/Popover";
import { styled } from "@mui/material/styles";
// CSS

const Root = styled(Popover)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
}));

// May need to populate based on id.

const PopoverComponent = (props) => {
  const {
    anchorEl,
    id,
    open,
    anchorOrigin,
    transformOrigin,
    children,
    onClose,
  } = props;

  return (
    <Root
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={anchorOrigin}
      transformOrigin={transformOrigin}
    >
      {children}
    </Root>
  );
};

PopoverComponent.propTypes = {
  anchorEl: PropTypes.objectOf(PropTypes.any),
  id: PropTypes.string,
  anchorOrigin: PropTypes.objectOf(PropTypes.any),
  transformOrigin: PropTypes.objectOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.objectOf(PropTypes.any),
  ]),
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

PopoverComponent.defaultProps = {
  anchorEl: undefined,
  id: undefined,
  anchorOrigin: undefined,
  transformOrigin: undefined,
  children: undefined,
  onClose: undefined,
  open: false,
};

export default PopoverComponent;
