import React from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  NavLink,
  LinkProps as RouterLinkProps,
  NavLinkProps as RouterNavLinkProps,
} from "react-router-dom";
import { DistributiveOmit } from "@mui/types";
import { styled } from "@mui/material/styles";
import { useAuth0 } from "../FischerFramework/FischerOAuth";

const PREFIX = "NavLink";

const classes = {
  activeNavLink: `${PREFIX}-activeNavLink`, // Add this line
};

const Root = styled("div")(({ theme }) => ({
  [`& .${classes.activeNavLink}`]: {
    borderRightWidth: theme.spacing(0.5),
    borderRightColor: theme.palette.secondary.main,
  },
}));

export interface NavLinkProps extends RouterNavLinkProps {
  fallback?: JSX.Element;
  has?: string[];
  hasAny?: boolean;
  icon?: React.ReactElement;
  primary: string;
}

export default ({
  fallback,
  has,
  hasAny,
  icon,
  primary,
  ...props
}: NavLinkProps) => {
  const { canView } = useAuth0();

  const renderLink = React.useMemo(
    () =>
      React.forwardRef<any, DistributiveOmit<RouterLinkProps, "to">>(
        (itemProps, ref) => (
          <Root>
            <NavLink
              ref={ref}
              title={primary}
              {...itemProps}
              {...props}
              activeClassName={classes.activeNavLink}
            />
          </Root>
        )
      ),
    [primary, props]
  );

  const fallbackComponent = fallback || null;

  return canView(has, hasAny) ? (
    <li
      style={{
        borderRightWidth: "4px",
        borderRightStyle: "solid",
        borderRightColor: "#e5b000",
      }}
    >
      <ListItemButton component={renderLink}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItemButton>
    </li>
  ) : (
    fallbackComponent
  );
};
