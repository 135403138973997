import React, { MouseEvent, useState } from "react";
import { styled } from "@mui/material/styles";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MenuItem from "@mui/material/MenuItem";
import { User } from "../FischerFramework/FischerOAuth";
import IconButton from "@mui/material/IconButton";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import Zoom from "@mui/material/Zoom";

const Root = styled(Divider)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

interface LogoutProps {
  logOut: () => void;
  user?: User;
}

export default ({ logOut, user }: LogoutProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  function onClick(event: MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <IconButton color="inherit" size="large" onClick={onClick}>
        <AccountCircle />
      </IconButton>
      <Menu
        id="account-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        TransitionComponent={Zoom}
      >
        <MenuItem disabled={true}>
          <ListItemIcon>
            <AccountCircle />
          </ListItemIcon>
          <ListItemText>{user?.nickname}</ListItemText>
        </MenuItem>
        <Root />
        <MenuItem
          onClick={() => {
            handleClose();
            logOut();
          }}
        >
          Log out
        </MenuItem>
      </Menu>
    </>
  );
};
