import React, { ChangeEvent, useState } from "react";
import { useField } from "formik";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormLabel from "@mui/material/FormLabel";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const PREFIX = "FormikRadioGroup";

const classes = {
  radioGroup: `${PREFIX}-radioGroup`,
  button: `${PREFIX}-button`,
};

const Root = styled(FormControl)(({ theme }) => ({
  [`& .${classes.radioGroup}`]: {
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  [`& .${classes.button}`]: {
    textTransform: "none",
  },
}));

export interface FormikRadioGroupProps extends RadioGroupProps {
  label: string;
  name: string;
  recommend?: boolean;
}

export default ({ className, ...props }: FormikRadioGroupProps) => {
  const [field, meta, helpers] = useField(props.name);
  const [showModal, setShowModal] = useState<boolean>(false);
  const notString = (
    <span style={{ color: "red", fontWeight: "bold" }}>
      &nbsp;<strong>NOT</strong>&nbsp;
    </span>
  );

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (Number.isInteger(field.value)) {
      helpers.setValue(parseInt(event.target.value, 10));
    } else if (typeof field.value === "boolean") {
      if (typeof event.target.value === "string") {
        event.target.value = event.target.value.trim().toLowerCase();
      }
      switch (event.target.value) {
        case "true":
        case "1":
        case "on":
        case "yes":
          return helpers.setValue(true);
        default:
          return helpers.setValue(false);
      }
    } else {
      helpers.setValue(event.target.value);
    }
  }
  function showConfirmModal() {
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
  }
  function handleDecision(decision: string) {
    helpers.setValue(decision);
    closeModal();
  }

  return (
    <Root
      className={className}
      fullWidth
      margin="normal"
      error={meta.touched && !!meta.error}
    >
      <Dialog
        open={showModal}
        onClose={closeModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
      >
        <DialogTitle id="alert-dialog-title" style={{ textAlign: "center" }}>
          Are you sure you would{notString}recommend Fischer Homes?
        </DialogTitle>
        <DialogActions>
          <Button
            className={classes.button}
            onClick={() => {
              handleDecision("No");
            }}
            color="inherit"
            style={{ textTransform: "none" }}
          >
            I would{notString}recommend Fischer Homes.
          </Button>
          <Button
            className={classes.button}
            onClick={() => {
              handleDecision("Yes");
            }}
            color="inherit"
            style={{ textTransform: "none" }}
            autoFocus
          >
            I would recommend Fischer Homes.
          </Button>
        </DialogActions>
      </Dialog>
      <FormLabel color="secondary" htmlFor={props.id}>
        {props.label}
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        color="secondary"
        data-testid={props.id}
        {...field}
        {...props}
        onChange={(event) =>
          event.target.value === "No" ? showConfirmModal() : onChange(event)
        }
      >
        {props.children}
      </RadioGroup>
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.error || " "}
      </FormHelperText>
    </Root>
  );
};
