import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import HeaderAndNav from "./components/HeaderAndNav/HeaderAndNav";
import CreateClosingSurvey from "./pages/CreateClosingSurvey/CreateClosingSurvey";
import Welcome from "./pages/Welcome/Welcome";
import ThankYou from "./pages/ThankYou/ThankYou";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Route from "./components/Route/Route";
import NavLink from "./components/NavLink/NavLink";
import { useMediaQuery } from "@mui/material";

const PREFIX = "App";
const classes = {
  appBarSpacer: `${PREFIX}-appBarSpacer`,
  content: `${PREFIX}-content`,
  darkModeContent: `${PREFIX}-darkModeContent`,
  container: `${PREFIX}-container`,
};

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  [`& .${classes.appBarSpacer}`]: theme.mixins.toolbar,
  [`& .${classes.content}`]: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
    transform: "translateZ(0)",
  },
  [`& .${classes.darkModeContent}`]: {
    backgroundColor: "#303030",
  },
  [`& .${classes.container}`]: {
    marginBottom: theme.spacing(8),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    width: "50%",
  },
}));

export default ({ logRocketURL }: any) => {
  const [viewPopup, setViewPopup] = useState<boolean>(false);

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  useEffect(() => {
    let visited = sessionStorage["alreadyVisited"];
    if (!visited) {
      setViewPopup(true);
      sessionStorage["alreadyVisited"] = true;
    }
  }, []);

  const handleClose = () => {
    setViewPopup(false);
  };

  return (
    <Root>
      <Dialog
        open={viewPopup}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirmation Number"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Closing Associate, please record the following confirmation number:{" "}
            {logRocketURL
              ? (logRocketURL as string).substr(
                  (logRocketURL as string).length - 6
                )
              : ""}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus color={"inherit"}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <HeaderAndNav title="Closing Surveys">
        <NavLink
          exact
          has={[
            "homeowner:manage",
            "homeowner:read",
            "community-site:read",
            "form-state:write",
          ]}
          icon={<ListAltIcon />}
          primary="Closing Survey"
          to="/"
        />
      </HeaderAndNav>
      <Container
        component="main"
        className={`${classes.content} ${
          prefersDarkMode ? classes.darkModeContent : ""
        }`}
        maxWidth={false}
      >
        <div className={classes.appBarSpacer} />
        <Grid className={classes.container}>
          <Switch>
            <Route path="/thank-you" hasAny>
              <ThankYou />
            </Route>
            <Route
              path="/"
              has={[
                "homeowner:manage",
                "homeowner:read",
                "community-site:read",
                "form-state:write",
              ]}
              fallback={<Welcome />}
            >
              <CreateClosingSurvey logRocketURL={logRocketURL} />
            </Route>
          </Switch>
        </Grid>
      </Container>
    </Root>
  );
};
