// React
import React from "react";

// Material UI
import Typography from "@mui/material/Typography";
import ContactSupport from "@mui/icons-material/ContactSupport";
import pjson from "../../package.json";

// Custom
import IconBtnPopoverComponent from "./IconBtnPopoverComponent";

/**
 * Account Component
 * @constant
 * @exports CompanyComponent
 * @returns {Component} returns Company Component
 */
const VersionComponent = () => (
  <>
    <IconBtnPopoverComponent
      id="account-popover"
      badge={0}
      icon={<ContactSupport />}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
    >
      <Typography>{`Version: ${pjson.version}`}</Typography>
    </IconBtnPopoverComponent>
  </>
);

export default VersionComponent;
