import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  ThemeOptions,
  useMediaQuery,
  adaptV4Theme,
} from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import "typeface-roboto";

interface FischerThemeProps extends React.ComponentProps<any> {
  themeOptions?: ThemeOptions;
}

export default ({ children, themeOptions }: FischerThemeProps) => {
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  const theme = React.useMemo(() => {
    const options: ThemeOptions = Object.assign({}, themeOptions, {
      palette: Object.assign({}, themeOptions?.palette, {
        type: prefersDarkMode ? "dark" : "light",
      }),
    });

    return responsiveFontSizes(createTheme(adaptV4Theme(options)));
  }, [prefersDarkMode, themeOptions]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
