import React from "react";
import { Route, RouteProps as RouterRouteProps } from "react-router-dom";
import { useAuth0 } from "../FischerFramework/FischerOAuth";
import Forbidden from "./Forbidden";

type RouteProps = RouterRouteProps & {
  children: JSX.Element;
  has?: string[];
  hasAny?: boolean;
  fallback?: JSX.Element;
};

const CustomRoute = ({
  children,
  has,
  hasAny,
  fallback,
  ...props
}: RouteProps) => {
  const { canView } = useAuth0();

  const fallbackComponent = fallback || <Forbidden />;

  return (
    <Route {...props}>
      {canView(has, hasAny) ? children : fallbackComponent}
    </Route>
  );
};

export default CustomRoute;
