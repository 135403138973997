import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "./FischerOAuth";
import ReactGA from "react-ga";

export default ({ children }: React.ComponentProps<any>) => {
  const location = useLocation();
  const { user } = useAuth0();

  useEffect(() => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(() => {
    if (user) {
      ReactGA.set({ userId: user.sub });
    }
  }, [user]);

  return children;
};
