import React from "react";
import FischerBoundary from "./FischerBoundary";
import { BrowserRouter as Router } from "react-router-dom";
import FischerOAuth from "./FischerOAuth";
import FischerLogging from "./FischerLogging";
import FischerTheme from "./FischerTheme";
import FischerSnackbar from "./FischerSnackbar";
import { Auth0ClientOptions } from "@auth0/auth0-spa-js/dist/typings/global";
import { FetchClient } from "./Fetch";
import FischerApolloProvider from "./FischerApolloProvider";
import { ThemeOptions } from "@mui/material";

interface FischerFrameworkProps extends React.ComponentProps<any> {
  config: Config;
}

export interface Config {
  fetchClients?: {
    [x: string]: FetchClient;
  };
  graphClientUri: string;
  oauth: Auth0ClientOptions;
  themeOptions?: ThemeOptions;
}

export default ({ children, config }: FischerFrameworkProps) => (
  <FischerBoundary>
    <FischerTheme themeOptions={config.themeOptions}>
      <FischerSnackbar>
        <Router>
          <FischerOAuth oauth={config.oauth}>
            <FischerLogging>
              <FischerApolloProvider uri={config.graphClientUri}>
                {children}
              </FischerApolloProvider>
            </FischerLogging>
          </FischerOAuth>
        </Router>
      </FischerSnackbar>
    </FischerTheme>
  </FischerBoundary>
);
