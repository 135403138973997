import React, { ChangeEvent } from "react";
import { useField } from "formik";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import { styled } from "@mui/material/styles";

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  alignSelf: "flex-start",
}));

interface FormikCheckboxProps extends CheckboxProps {
  label: string;
  name: string;
}

export default (props: FormikCheckboxProps) => {
  const [field, meta, helpers] = useField(props.name);

  function onChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.checked) {
      helpers.setValue("Yes");
    } else {
      helpers.setValue("");
    }
  }

  return (
    <FormControl
      error={meta.touched && !!meta.error}
      id={props.id + "-control"}
      margin="normal"
    >
      <FormControlLabel
        control={
          <StyledCheckbox
            color="primary"
            data-testid={props.id}
            {...field}
            {...props}
            onChange={onChange}
          />
        }
        id={props.id + "-label"}
        label={props.label}
      />
      <FormHelperText error={meta.touched && !!meta.error}>
        {meta.error || " "}
      </FormHelperText>
    </FormControl>
  );
};
