import React from "react";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Radio, useMediaQuery, useTheme } from "@mui/material";
import { styled } from "@mui/material/styles";
import FormikRadioGroup, {
  FormikRadioGroupProps,
} from "../../../components/FormikRadioGroup/FormikRadioGroup";
import { useField } from "formik";
import Typography from "@mui/material/Typography";

const PREFIX = "SatisfactionQuestion";

const classes = {
  lgLabel: `${PREFIX}-lgLabel`,
};

const Root = styled(Typography)(({ theme }) => ({
  [`& .${classes.lgLabel}`]: {
    marginTop: theme.spacing(5),
  },
}));

interface SatisfactionQuestion extends FormikRadioGroupProps {
  primary?: boolean;
  displayNA?: boolean;
}

export default (props: SatisfactionQuestion) => {
  const [field] = useField(props.name);
  const theme = useTheme();
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const displayNA = props.displayNA;

  return (
    <FormikRadioGroup {...props} color="secondary" row={mdUp}>
      {mdUp ? <Root className={classes.lgLabel}>Satisfied</Root> : null}
      <FormControlLabel
        control={<Radio checked={field.value === 5} color="secondary" />}
        label={mdUp ? "5" : "Very Satisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={5}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 4} color="secondary" />}
        label={mdUp ? "4" : "Satisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={4}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 3} color="secondary" />}
        label={mdUp ? "3" : "Neutral"}
        labelPlacement={mdUp ? "top" : "end"}
        value={3}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 2} color="secondary" />}
        label={mdUp ? "2" : "Dissatisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={2}
      />
      <FormControlLabel
        control={<Radio checked={field.value === 1} color="secondary" />}
        label={mdUp ? "1" : "Very Dissatisfied"}
        labelPlacement={mdUp ? "top" : "end"}
        value={1}
      />
      {displayNA && (
        <FormControlLabel
          control={<Radio checked={field.value === 0} color="secondary" />}
          label={"N/A"}
          labelPlacement={mdUp ? "top" : "end"}
          value={0}
        />
      )}
      {mdUp ? <Root className={classes.lgLabel}>Dissatisfied</Root> : null}

    </FormikRadioGroup>
  );
};
