import React, { MouseEventHandler } from "react";
import { styled } from "@mui/material/styles";
import { IconButton, useMediaQuery, useTheme } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";

export const drawerWidth = 240;

const PREFIX = "Nav";

const classes = {
  drawerPaper: `${PREFIX}-drawerPaper`,
  darkModeDrawerPaper: `${PREFIX}-darkModeDrawerPaper`,
  drawerPaperClose: `${PREFIX}-drawerPaperClose`,
  toolbarIcon: `${PREFIX}-toolbarIcon`,
};

const Root = styled(Drawer)(({ theme }) => ({
  [`& .${classes.drawerPaper}`]: {
    display: "flex",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [`& .${classes.darkModeDrawerPaper}`]: {
    backgroundColor: "#424242",
  },
  [`& .${classes.drawerPaperClose}`]: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
  },
  [`& .${classes.toolbarIcon}`]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
}));

interface NavProps extends React.ComponentProps<any> {
  handleDrawerToggle: MouseEventHandler;
  onClick: MouseEventHandler;
  open: boolean;
  variant?: "permanent" | "persistent" | "temporary";
}

export default ({
  children,
  handleDrawerToggle,
  onClick,
  open,
  variant,
}: NavProps) => {
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up("sm"));

  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)", {
    noSsr: true,
  });

  return (
    <Root
      anchor={theme.direction === "rtl" ? "right" : "left"}
      variant={variant || (smUp ? "permanent" : "temporary")}
      classes={{
        paper: `${classes.drawerPaper} ${!open && classes.drawerPaperClose} ${
          prefersDarkMode ? classes.darkModeDrawerPaper : ""
        }`,
      }}
      open={open}
      onClose={handleDrawerToggle}
    >
      <nav onClick={onClick}>
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerToggle} size="large">
            <ChevronLeftIcon fontSize="small" />
          </IconButton>
        </div>
        <Divider />
        <List disablePadding={true}>{children}</List>
      </nav>
    </Root>
  );
};
